import PageWrapper from "../components/PageWrapper";
import HeroBanner from "../components/Banners Uplaod/HeroBanner";

const UpdateImage = () => {
  return (
    <>
      <PageWrapper>
        <HeroBanner />
      </PageWrapper>
    </>
  );
};

export default UpdateImage;
