import React, { useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { postData } from "../FatchApi/FatchApi";
import { useNavigate } from "react-router-dom";

const BulkProductAdd = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [isOpen, setIsopen] = useState(false);
  const [resData, setResData] = useState();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setUploadStatus("idle");
    } else {
      setFile(null);
      setUploadStatus("error");
      alert(
        "Please select a valid Excel file (.xlsx or .xls) containing product data"
      );
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setUploadStatus("error");
      return;
    }

    setUploading(true);
    setUploadStatus("idle");
    const contanttype = "";
    const formData = new FormData();

    formData.append("file", file);

    // Simulating file upload with a timeout
    // In a real application, you would send the file to your server here
    const res = await postData("api/product/upload-products", {
      data: formData,
      contanttype,
    });
    if (res.success) {
      setUploadStatus("success");
      setUploading(false);
      setResData(res.summary);
      setIsopen(true);
      // navigate("/products");
    } else {
      setUploadStatus("error");
      setResData(res.summary);
    }
  };

  const closeModal = () => {
    setIsopen(false);
    navigate("/products");
  };
  console.log(resData);
  return (
    <>
      <PageWrapper>
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold text-center mb-6">
              Bulk Product Uploader
            </h2>
            <form onSubmit={handleUpload} className="space-y-4">
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="product-excel-file"
                  className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span>
                    </p>
                    <p className="text-xs text-gray-500">
                      Excel file with product data (XLSX, XLS)
                    </p>
                  </div>
                  <input
                    id="product-excel-file"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              {file && (
                <p className="text-sm text-gray-500 text-center">
                  Selected file: {file.name}
                </p>
              )}
              <button
                type="submit"
                disabled={!file || uploading}
                className={`w-full py-2 px-4 rounded-md text-white font-semibold ${
                  !file || uploading
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {uploading ? "Uploading Products..." : "Upload Bulk Products"}
              </button>
            </form>
            {uploadStatus === "success" && (
              <div className="mt-4 flex items-center justify-center text-green-500">
                <span>Products uploaded successfully!</span>
              </div>
            )}
            {uploadStatus === "error" && (
              <div className="mt-4 flex items-center justify-center text-red-500">
                <span>Error uploading products. Please try again.</span>
              </div>
            )}
          </div>
        </div>
        {isOpen ? (
          <div
            id="crypto-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800">
                    Upload Summary
                  </h2>
                  <p
                    className={`text-lg mb-4 ${
                      resData?.success ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {resData?.message}
                  </p>
                  <div className="space-y-2">
                    <p className="text-gray-700">
                      <span className="font-semibold">Total Products:</span>{" "}
                      {resData?.totalProducts}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Total Variations:</span>{" "}
                      {resData?.totalVariations}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Successful Uploads:</span>{" "}
                      {resData?.successfulUploads}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">
                        Unsuccessful Uploads:
                      </span>{" "}
                      {resData?.unsuccessfulUploads}
                    </p>
                  </div>
                </div>
                <div className="bg-gray-100 px-6 py-4 rounded-b-lg">
                  <button
                    onClick={closeModal}
                    className="w-full px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </PageWrapper>
    </>
  );
};

export default BulkProductAdd;
