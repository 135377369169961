import { useEffect, useState } from "react";
import Spinner from "../Loader/Spinner";
import axios from "axios";
import {
  BaseURL,
  DeleteData,
  getData,
  postData,
} from "../../FatchApi/FatchApi";
import Toastify from "../Toast/Toastify";
import { toast } from "react-toastify";

export default function HeroBanner() {
  const [heroBanners, setHeroBanners] = useState({
    hero1: "",
    hero2: "",
    hero3: "",
  });
  const [bannersPrev, setBannersPrev] = useState({
    hero1: "",
    hero2: "",
    hero3: "",
  });

  const [banners, setBanners] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const getHeroBanners = async () => {
    const res = await getData(`api/banner/get-hero-banners`);

    const imgUrl = {};
    await res?.hero?.map((item) => {
      const [key] = Object.keys(item);
      imgUrl[key] = item[key];
    });
    setBanners(imgUrl);
  };
  useEffect(() => {
    getHeroBanners();
  }, []);

  const heroBannerKeys = [];
  for (const key in heroBanners) {
    heroBannerKeys.push(key);
  }
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBannersPrev((prev) => ({
        ...prev,
        [name]: reader.result,
      }));
    };
    setHeroBanners((prev) => ({
      ...prev,
      [name]: file,
    }));
  };
  const uploadBanner = async (item) => {
    setSpinner(true);
    const formData = new FormData();
    const contanttype = "";
    formData.append(`${item}`, heroBanners[item]);

    const res = await postData("api/banner/upload-hero-banner", {
      data: formData,
      contanttype,
    });

    if (res.success) {
      setHeroBanners((prev) => ({
        ...prev,
        [item]: "",
      }));
      setSpinner(false);
      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getHeroBanners();
    } else {
      setSpinner(false);
      setHeroBanners((prev) => ({
        ...prev,
        [item]: "",
      }));
      toast.error(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const deleteSingleHeroBanner = async (item) => {
    setSpinner(true);
    const res = await DeleteData(
      `api/banner/delete-single-hero-banner/${item}`
    );
    if (res.success) {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getHeroBanners();
      setSpinner(false);
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {spinner ? <Spinner /> : ""}
      <Toastify close={"2000"} />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-center text-white">
          Hero Banner
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {heroBannerKeys.map((item, i) => {
            return (
              <div>
                <div className="relative group">
                  <img
                    src={
                      (bannersPrev && bannersPrev[item]) ||
                      (banners && banners[item]) ||
                      "../camera-placeholder.png"
                    }
                    alt={`Gallery image`}
                    className="w-full h-auto object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
                  />

                  <div className="inset-0 hidden group-hover:absolute group-hover:flex bg-black bg-opacity-50 items-center justify-center rounded-lg transition-opacity duration-300">
                    <label
                      for={`file${i}`}
                      role="button"
                      className="bg-white  text-gray-800 font-semibold py-2 px-4 rounded-full shadow-lg hover:bg-gray-100 transition-colors duration-300"
                    >
                      Change Image
                    </label>
                    {banners && banners[item] ? (
                      <label
                        // for={`file${i}`}
                        onClick={() => deleteSingleHeroBanner(item)}
                        role="button"
                        className="bg-red-900 mx-2  text-white font-semibold py-2 px-4 rounded-full shadow-lg hover:bg-red-400 transition-colors duration-300"
                      >
                        Delete
                      </label>
                    ) : (
                      ""
                    )}
                  </div>

                  <input
                    type="file"
                    name={item}
                    className="hidden"
                    id={`file${i}`}
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                {heroBanners[item] !== "" ? (
                  <>
                    {" "}
                    <div className="flex justify-center my-4">
                      <label
                        onClick={() => uploadBanner(item)}
                        for="uploadFile1"
                        class="flex bg-gray-800 hover:bg-gray-700 text-white text-base px-5 py-3 outline-none rounded w-max cursor-pointer mx-auto font-[sans-serif]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 mr-2 fill-white inline"
                          viewBox="0 0 32 32"
                        >
                          <path
                            d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                            data-original="#000000"
                          />
                          <path
                            d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                            data-original="#000000"
                          />
                        </svg>
                        Upload
                      </label>
                    </div>
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
