import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ContentBox from "../components/contents/ContentBox";
import PageWrapper from "../components/PageWrapper";
import OrderDetailsProduct from "../components/products/OrderDetailsProduct";
import { BaseURL, getData, putData2 } from "../FatchApi/FatchApi";
import { useParams } from "react-router-dom";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";

const CustomerQueryDetails = () => {
  const { id } = useParams();
  const [query, setQuery] = useState();

  const getsingleQuery = async () => {
    const res = await getData(`api/customer-form/get-single-query/${id}`);

    setQuery(res?.query);
  };
  const updateView = () => {
    const res = putData2(`api/customer-form/view-queri/${id}`);
  };
  useEffect(() => {
    getsingleQuery();
    updateView();
  }, []);

  return (
    <PageWrapper>
      <ContentBox name={`${query?.category} id: ${id}`}>
        <Toastify close={"2000"} />
        <div className="border-b border-b-glitch-box"></div>
        <div className="px-5">
          {/* <hr className="w-full border-b border-b-glitch-box my-5" /> */}

          {/* Customer details */}
          <div className="mt-0">
            <div className="text-white flex items-center justify-between">
              <div className="font-medium ">{query?.category} Details</div>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Name</span>
              <span>{query?.name} </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Delaer Name</span>
              <span>{query?.delaerName} </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Email</span>
              <span>{query?.email} </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Mobile</span>
              <div>
                <span>{query?.phoneNumber} </span>
                <button className="material-icons-outlined text-lg px-1 bg-blue-500 text-white rounded">
                  phone
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Date</span>
              <span>
                {query?.createdAt
                  ? new Date(query?.createdAt).toLocaleString()
                  : ""}
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Attachment </span>
              <span>
                {query?.file ? (
                  <>
                    <a
                      href={query.file}
                      className="text-blue-800"
                      target="blank"
                    >
                      {" "}
                      View
                    </a>
                  </>
                ) : (
                  "No Attachment"
                )}
              </span>
            </div>
            <div className="pb-4">
              <p className="text-white my-2">Message in {query?.category} </p>
              <p className="bg-white p-4  rounded-md">{query?.message}</p>
            </div>
          </div>
        </div>
      </ContentBox>
    </PageWrapper>
  );
};

export default CustomerQueryDetails;
