import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";

import Slider from "react-slick";

// Import slick-carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BaseURL, getData } from "../FatchApi/FatchApi";

import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Loader/Spinner";

const ManageImages = () => {
  const [allImages, setAllImages] = useState("");
  const [spinner, setSpinner] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
  };

  const getImages = async () => {
    const res = await getData("api/banner/get-hero-banners");
    const imgUrl = [];
    res.hero.map((item) => {
      const key = Object.keys(item);
      imgUrl.push(item[key]);
    });
    setAllImages(imgUrl);
  };
  useEffect(() => {
    getImages();
  }, []);

  const heroDemo = [
    "../camera-placeholder.png",
    "../camera-placeholder.png",
    "../camera-placeholder.png",
  ];
  const heroData = [];
  for (const key in allImages?.heroBanner) {
    heroData.push(key);
  }
  return (
    <PageWrapper>
      {spinner ? <Spinner /> : ""}
      <section className="bg-gradient-to-b from-gray-200 to-gray-400 bg-fixed h-full pt-5">
        <div className="container mx-auto px-4 w-full max-w-4xl">
          <div className="relative">
            <Slider {...settings}>
              {allImages.length !== 0
                ? allImages.map((item, index) => (
                    <div className="relative group p-2" key={index}>
                      <Link to="/manage-images/hero-banner-change">
                        <img
                          src={item}
                          className="object-cover w-full h-auto rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-105"
                          alt="Best spices and masala brand in India"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="text-white text-lg font-semibold">
                            <h2 className="text-xl">
                              Hero Banner on Home page
                            </h2>
                            <p className="mt-2">Click On Banner to change it</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                : heroDemo.map((src, index) => (
                    <div className="relative group p-2" key={index}>
                      <Link to="/manage-images/hero-banner-change">
                        <img
                          src={src}
                          className="object-cover w-full h-auto rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-105"
                          alt="Best spices and masala brand in India"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="text-white text-lg font-semibold">
                            <h2 className="text-xl">
                              Hero Banner on Home page
                            </h2>
                            <p className="mt-2">Click On Banner to change it</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default ManageImages;
