import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import NotFoundPage from "./pages/404Page";
import CustomerListPage from "./pages/CustomerListPage";
import ManageOrderPage from "./pages/ManageOrderPage";
import PaymentPage from "./pages/PaymentPage";
import ProductPage from "./pages/ProductPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import { PageListContext } from "./contexts/PageContext";
import Verifyotp from "./pages/Verifyotp";
import ManageUserOrder from "./pages/ManageUserOrder";
import ManageBlog from "./pages/ManageBlog";

import ManageImages from "./pages/ManageImages";
import UpdateImage from "./pages/UpdateImage";
import ManageReviews from "./pages/ManageReviews";
import ReviewDetailsPage from "./pages/ReviewDetailsPage";
import ProductCategory from "./pages/ProductCategory";
import DiscountAndCoupon from "./pages/DiscountAndCoupon";
import ManageOffer from "./pages/ManageOffer";
import UserManagement from "./pages/UserManagement";
import CustomerQuery from "./pages/CustomerQuery";
import CustomerQueryDetails from "./pages/CustomerQueryDetails";
import ManageUserCart from "./pages/ManageUserCart";
import CartDetails from "./components/Cart/CartDetails";
import CartDetailsPage from "./pages/CartDetailsPage";
import User from "./pages/User";
import BulkProductAdd from "./pages/BulkProductAdd";

/* App routes controller  */
const App = () => {
  const [pageList, setPageList] = useState([
    { label: "Dashboard", slug: "/Dashboard", icon: "dashboard" },
    { label: "Manage Users", slug: "/manage-users", icon: "people_alt" },
    { label: "Manage orders", slug: "/manage-orders", icon: "leaderboard" },

    // {
    //   label: "Online payments",
    //   slug: "/online-payments",
    //   icon: "account_balance_wallet",
    // },
    { label: "Product Category", slug: "/product-category", icon: "shop_2" },

    { label: "Discount", slug: "/discount-coupon", icon: "shop_2" },
    // { label: "Manage Offer", slug: "/offer", icon: "shop_2" },
    { label: "Products", slug: "/products", icon: "shop_2" },
    { label: "Manage Cart", slug: "/manage-cart", icon: "shop_2" },
    // { label: "Settings", slug: "/settings", icon: "settings" },
    // { label: "Manage Blogs", slug: "/manage-blogs", icon: "shop" },
    // { label: "Manage Recipes", slug: "/manage-recipes", icon: "shop" },
    { label: "Manage Images", slug: "/manage-images", icon: "shop" },
    { label: "Customer Queries", slug: "/customer-queries", icon: "shop" },
    { label: "Manage News", slug: "/news", icon: "shop_2" },
    {
      label: "Manage Testimonials",
      slug: "/manage-testimonials",
      icon: "shop_2",
    },
  ]);

  // Check if a token exists in local storage
  const hasToken = !!localStorage.getItem("token");

  // State to track if the user is authenticated
  const [authenticated, setAuthenticated] = useState(hasToken);

  useEffect(() => {
    // Update the authentication state based on the presence of a token
    setAuthenticated(hasToken);
  }, [hasToken]);
  console.log(authenticated);
  return (
    <PageListContext.Provider value={{ pageList, setPageList }}>
      <BrowserRouter>
        <Routes>
          {/* Login page */}
          {authenticated ? (
            <>
              {/* Customer lists */}
              <Route path="/manage-users" caseSensitive element={<User />} />
              <Route
                path="/manage-users/B2C"
                caseSensitive
                element={<UserManagement />}
              />
              <Route
                path="/manage-users/B2B"
                caseSensitive
                element={<UserManagement />}
              />
              <Route
                path="/customer-list/:userId"
                caseSensitive
                element={<ManageUserOrder />}
              />
              {/* Manage orders */}
              <Route
                path="/manage-orders"
                caseSensitive
                element={<ManageOrderPage />}
              />
              {/* Manage Reviews */}
              <Route>
                <Route
                  path="/customer-queries"
                  caseSensitive
                  element={<CustomerQuery />}
                />
                <Route
                  path="customer-queries/:id"
                  caseSensitive
                  element={<CustomerQueryDetails />}
                />
                <Route />
              </Route>

              {/* Online payments */}

              {/* Order details */}
              <Route
                path="/order/:orderId"
                caseSensitive
                element={<OrderDetailsPage />}
              />
              <Route
                path="/manage-cart"
                caseSensitive
                element={<ManageUserCart />}
              />
              <Route
                path="/manage-cart/:userId"
                caseSensitive
                element={<CartDetailsPage />}
              />
              <Route path="/news" caseSensitive element={<ManageBlog />} />
              <Route
                path="/discount-coupon"
                caseSensitive
                element={<DiscountAndCoupon />}
              />
              <Route>
                <Route
                  path="/manage-testimonials"
                  caseSensitive
                  element={<ManageReviews />}
                />
                <Route
                  path="/manage-testimonials/view-testimonials"
                  caseSensitive
                  element={<ReviewDetailsPage />}
                />
              </Route>
              <Route>
                <Route
                  path="/manage-images"
                  caseSensitive
                  element={<ManageImages />}
                />
                <Route
                  path="manage-images/hero-banner-change"
                  caseSensitive
                  element={<UpdateImage />}
                />
              </Route>

              {/* Products */}
              <Route path="/products" caseSensitive element={<ProductPage />} />
              <Route
                path="/bulk-product-add"
                caseSensitive
                element={<BulkProductAdd />}
              />
              <Route
                path="/product-category"
                caseSensitive
                element={<ProductCategory />}
              />

              <Route
                path="/Dashboard"
                caseSensitive
                element={<DashboardPage />}
              />
            </>
          ) : (
            <>
              <Route path="/" caseSensitive element={<LoginPage />} />
              <Route path="/verify-otp" caseSensitive element={<Verifyotp />} />
            </>
          )}

          {/* 404 page not found */}
          <Route path="*" element={<DashboardPage />} />
        </Routes>
      </BrowserRouter>
    </PageListContext.Provider>
  );
};

export default App;
