import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { getData } from "../FatchApi/FatchApi";
import { Link } from "react-router-dom";

const User = () => {
  const [user, setUser] = useState([]);

  const getUser = async () => {
    const res = await getData("api/user/get-users");
    if (res.success) {
      setUser(res.users);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const B2C = user?.filter((item) => item.userType == "B2C");
  const B2B = user?.filter((item) => item.userType == "B2B");
  return (
    <PageWrapper>
      <div className="flex justify-center mt-[250px]">
        <div className="grid grid-cols-2  items-center w-[700px] gap-2 max-[500px]:grid-cols-1 px-3">
          <div className="group w-full rounded-lg bg-[#673ab7] p-5 transition relative duration-300 cursor-pointer hover:translate-y-[3px] hover:shadow-[0_-8px_0px_0px_#2196f3]">
            <Link to={`/manage-users/B2C`} state={{ userType: "B2C" }}>
              <p className="text-white text-2xl font-semibold">{B2C.length}</p>
              <p className="text-white  text-xl font-semibold">B2C</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                height={36}
                width={36}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="group-hover:opacity-100 text-white absolute right-[10%] top-[50%] translate-y-[-50%] opacity-20 transition group-hover:scale-110 duration-300"
              >
                <circle cx="12" cy="7" r="4"></circle>
                <path d="M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"></path>
              </svg>
            </Link>
          </div>
          <div className="group w-full rounded-lg bg-[rgb(41,49,79)] p-5 transition relative duration-300 cursor-pointer hover:translate-y-[3px] hover:shadow-[0_-8px_0px_0px_#FFD700]">
            <Link to={`/manage-users/B2B`} state={{ userType: "B2B" }}>
              <p className="text-white text-2xl font-semibold">{B2B.length}</p>
              <p className="text-white  text-xl font-semibold">B2B</p>
              <svg
                className="group-hover:opacity-100 text-white absolute right-[10%] top-[50%] translate-y-[-50%] opacity-20 transition group-hover:scale-110 duration-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                height={36}
                width={36}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="9" cy="7" r="4"></circle>
                <circle cx="15" cy="7" r="4"></circle>
                <path d="M5 21v-2a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4v2"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default User;
