import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { DeleteData, getData, postData, putData2 } from "../FatchApi/FatchApi";
import DataTable from "react-data-table-component";
import State from "../constant/State.json";
import Spinner from "../components/Loader/Spinner";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
const UserManagement = () => {
  const location = useLocation();
  const { userType } = location.state;

  const [users, setUsers] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    fullName: "",
    email: "",
    number: "",
    password: "",
    state: " ",
    city: "",
    pincode: "",
    userType: "",
    active: "",
  });
  const getUser = async () => {
    const res = await getData("api/user/get-users");
    if (res.success) {
      const data = res.users.filter((item) => item.userType == userType);
      setUsers(data);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const toggleModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
  };
  const closeModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
    setUserId("");
    setCustomerData({
      fullName: "",
      email: "",
      number: "",
      password: "",
      state: " ",
      city: "",
      pincode: "",
      userType: "",
      active: "",
    });
  };
  const onchangeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name == "active") {
      setCustomerData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setCustomerData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const CreateUser = async () => {
    if (
      !customerData.fullName ||
      !customerData.email ||
      !customerData.number ||
      customerData.number.length !== 10 ||
      !customerData.password ||
      !customerData.state ||
      !customerData.city ||
      !customerData.pincode ||
      !customerData.userType
    ) {
      alert(
        "Please fill all fields and make sure the number is exactly 10 digits."
      );
    } else {
      setSpinner(true);
      const formData = {
        contanttype: "application/json",
        data: JSON.stringify(customerData),
      };
      if (userId == "") {
        const res = await postData("api/user/create-user", formData);
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          closeModal();
          getUser();
          setSpinner(false);
        } else {
          setSpinner(false);
          toast.error(res.message);
        }
      } else {
        const res = await putData2(`api/user/update-user/${userId}`, formData);
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSpinner(false);
          closeModal();
          getUser();
        } else {
          setSpinner(false);
          toast.error(res.error);
        }
      }
    }
  };

  const columns = [
    {
      name: "S.N",
      selector: (row, i) => 1 + i,
      sortable: true,
      center: true,
      width: "70px",
    },
    {
      name: "Full Name",
      selector: (row, i) => row.fullName,
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Email",
      selector: (row, i) => row.email,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Number",
      selector: (row, i) => row.number,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Password",
      selector: (row, i) => row.password,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Address",
      selector: (row, i) => `${row.city}, ${row.state}, ${row.pincode}`,
      sortable: true,
      center: true,
      width: "250px",
    },
    {
      name: "User Type",
      selector: (row, i) => (
        <>
          <div
            className={
              row.userType == "B2B" ? "px-3 bg-blue-200" : "px-3 bg-yellow-200"
            }
          >
            <p
              className={
                row.userType == "B2B"
                  ? "px-3 text-blue-700"
                  : "px-3 text-yellow-500"
              }
            >
              {row.userType}
            </p>
          </div>
        </>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Active",
      selector: (row, i) => (
        <>
          <div
            className={
              row.active
                ? "px-4 bg-green-200 flex justify-center rounded-lg"
                : "px-5 bg-red-200 flex justify-center rounded-lg"
            }
          >
            <p
              className={
                row.active
                  ? "font-semibold px-4 text-green-700"
                  : "px-4 text-red-700 font-semibold"
              }
            >
              {row.active ? "Active" : "Not Active"}
            </p>
          </div>
        </>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row, i) => (
        <div className=" flex flex-row ">
          <div className="mx-2">
            <svg
              role="button"
              onClick={() => {
                setUserId(row._id);
                setCustomerData(row);
                toggleModal();
              }}
              version="1.1"
              className="text-blue-600"
              fill="currentColor"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="20px"
              width="20px"
              viewBox="0 0 348.882 348.882"
              style={{ enableBackground: "new 0 0 348.882 348.882" }}
              xmlSpace="preserve"
            >
              <g>
                <path
                  d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
		c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
		c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
		C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
		L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
		c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z"
                />
                <path
                  d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
		c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
		s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
		c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z"
                />
              </g>
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
            </svg>
          </div>
          <div>
            {/* <svg
              role="button"
           
              className="text-red-600"
              fill="currentColor"
              height="20px"
              viewBox="733 450 64.901 90"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
              isolation="isolate"
            >
              <path
                d="m755.532 457.988h19.838c.036-.129.055-.264.055-.4v-3.195c0-.369-.139-.729-.399-.988-.27-.26-.619-.41-.989-.41h-17.173c-.37 0-.719.15-.989.41-.259.259-.399.619-.399.988v3.195c0 .136.019.271.056.4zm-18.368 2.995h56.574c.309 0 .609.12.828.34.22.219.34.519.34.829v5.361c0 .31-.12.609-.34.819-.219.22-.519.35-.828.35h-56.574c-.31 0-.609-.13-.829-.35-.22-.21-.34-.509-.34-.819v-5.361c0-.31.12-.61.34-.829.22-.22.519-.34.829-.34zm29.794 64.043c0 .399-.159.779-.439 1.068-.28.28-.659.429-1.058.429-.4 0-.779-.149-1.059-.429-.279-.289-.439-.669-.439-1.068v-41.018c0-.399.16-.779.439-1.058.28-.28.659-.44 1.059-.44.399 0 .778.16 1.058.44.28.279.439.659.439 1.058zm-13.06-41.068 2.147 40.968c.02.389-.11.779-.38 1.078-.269.3-.639.47-1.038.489-.389.02-.779-.109-1.078-.379-.29-.269-.47-.639-.49-1.038l-2.146-40.958c-.02-.399.11-.789.379-1.088.27-.29.639-.47 1.039-.49.389-.02.778.12 1.078.38.289.269.469.639.489 1.038zm-12.783-12.285 3.867 63.546c0 .311.11.622.289.849.19.215.44.347.709.347h38.941c.27 0 .519-.132.709-.347.18-.227.289-.538.289-.849l3.867-63.546zm11.378-13.685h-15.334c-2.295 0-4.159 1.864-4.159 4.159v5.367c0 2.295 1.864 4.159 4.159 4.159h.96l3.867 63.544c0 2.64 1.79 4.783 3.994 4.783h38.941c2.204 0 3.994-2.143 3.994-4.783l3.867-63.544h.96c2.295 0 4.159-1.864 4.159-4.159v-5.367c0-2.295-1.864-4.159-4.159-4.159h-15.334c.012-.131.018-.263.018-.397v-3.2c0-2.423-1.968-4.391-4.391-4.391h-17.168c-2.424 0-4.391 1.968-4.391 4.391v3.2c0 .134.006.266.017.397zm27.515 26.13-2.146 40.958c-.02.399-.2.769-.49 1.038-.299.27-.689.399-1.078.379-.399-.019-.769-.189-1.038-.489-.27-.299-.4-.689-.38-1.078l2.147-40.968c.02-.399.2-.769.489-1.038.3-.26.689-.4 1.079-.38.399.02.768.2 1.038.49.27.299.399.689.379 1.088z"
                fillRule="evenodd"
              />
            </svg> */}
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <PageWrapper>
      <Toastify close={"2000"} />
      {spinner ? <Spinner /> : ""}
      <div className="bg-white grid">
        <div className="flex justify-end p-4">
          <button
            className="bg-blue-600 text-white p-2 font-semibold rounded-md mr-4"
            onClick={toggleModal}
          >
            + Add Customer
          </button>
        </div>
        <h2 className="text-2xl font-bold ml-4"> {userType} Customers</h2>
        <DataTable columns={columns} data={users} />

        <div
          id="crud-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="hidden overflow-y-auto overflow-x-hidden fixed t z-50 justify-center items-center w-full md:inset-0  max-h-full"
        >
          <div className="relative p-4 w-full max-w-xl max-h-full mx-auto">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add New Customer
                </h3>
                <button
                  type="button"
                  onClick={closeModal}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <form className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-3">
                  <div className="">
                    <label
                      htmlFor="fullName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      id="fullName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=" Full Name"
                      value={customerData.fullName}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Email"
                      value={customerData.email}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="productInfo"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Number
                    </label>
                    <input
                      type="text"
                      name="number"
                      id="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Number"
                      value={customerData.number}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="text"
                      name="password"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Password"
                      value={customerData?.password}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="state"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      State
                    </label>

                    <select
                      id="state"
                      name="state"
                      onChange={onchangeHandler}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Select State</option>
                      {State.map((item) => {
                        return (
                          <>
                            <option
                              value={item.name}
                              selected={item.name == customerData.state}
                            >
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="city"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="city"
                      value={customerData?.city}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="pincode"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Pincode"
                      value={customerData?.pincode}
                      onChange={onchangeHandler}
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="userType"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      User Type
                    </label>

                    <select
                      id="countries"
                      name="userType"
                      onChange={onchangeHandler}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose User Type</option>
                      <option
                        value="B2B"
                        selected={customerData.userType == "B2B"}
                      >
                        B2B
                      </option>
                      <option
                        value="B2C"
                        selected={customerData.userType == "B2C"}
                      >
                        B2C
                      </option>
                    </select>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="active"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Active
                    </label>
                    <input
                      type="checkbox"
                      name="active"
                      checked={customerData.active}
                      onChange={onchangeHandler}
                      id="active"
                      className="w-5 h-5 mx-auto border border-gray-300 rounded-md bg-white checked:bg-blue-500 checked:border-blue-500 transition-all duration-200 ease-in-out cursor-pointer"
                    />
                  </div>
                </div>
              </form>
              <div className="flex justify-end mx-3 pb-3">
                <button
                  onClick={CreateUser}
                  className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {userId !== "" ? "Update User" : "Create User"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default UserManagement;
