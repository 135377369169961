import React, { useState } from "react";

import { BaseURL, DeleteData, putData } from "../../FatchApi/FatchApi";
import { Link } from "react-router-dom";

const QueryCard = ({ data, onchange, setshow, setEditID }) => {
  return (
    <Link to={`/customer-queries/${data._id}`}>
      <div className={"w-full py-1 flex"}>
        {/* Product order details */}
        <div className="flex items-center justify-between w-full">
          {/* Product preview thumbnail */}
          <div className="flex relative justify-center items-center w-28 h-28 bg-orange-400 rounded  ">
            <p className="font-bold  text-white text-xl">{data.category}</p>
            {!data?.viewedByAdmin ? (
              <button
                type="button"
                class="mb-[500px] bg-green-700 text-white p-2 rounded leading-none absolute top-0 right-0 animate-bounce  animate-fadeIn"
              >
                <p className="text-white font-bold">New</p>
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="ml-5 flex-1">
            <div className="text-white text-xl">{data.name}</div>
            <div className="text-gray-300">
              {new Date(data.createdAt).toLocaleString()}{" "}
            </div>
            <div className="text-glitch-orange text-lg font-bold">
              {data.price}
            </div>

            {/* Prodcut status */}
          </div>
        </div>

        {/* More options button */}
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        ></div>
      </div>
    </Link>
  );
};

export default QueryCard;
