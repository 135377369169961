import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { getData, postData } from "../FatchApi/FatchApi";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";

const DiscountAndCoupon = () => {
  const [getdiscount, setGetdiscount] = useState();
  const [discountData, setDiscountData] = useState({
    B2CdiscountPercentage: getdiscount?.B2CdiscountPercentage,
    B2BdiscountPercentage: getdiscount?.B2CdiscountPercentage,
  });
  const getDiscount = async () => {
    const res = await getData("api/discount/get-discount");

    setGetdiscount(res.discount[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      discountData.B2BdiscountPercentage !== "" &&
      discountData.B2CdiscountPercentage !== ""
    ) {
      const formData = {
        contanttype: "Application/json",
        data: JSON.stringify(discountData),
      };
      const res = await postData("api/discount/create-discount", formData);

      if (res.success) {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getDiscount();
        setDiscountData({
          B2CdiscountPercentage: "",
          B2BdiscountPercentage: "",
        });
      } else {
        alert(res.message);
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Enter Discout for B2C and B2B both", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const OnchangeHandler = (e) => {
    const { name, value } = e.target;
    setDiscountData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    getDiscount();
  }, []);

  return (
    <PageWrapper>
      <Toastify close={"2000"} />
      <div className="container">
        <div className="my-5">
          <h2 className="text-[25px] p-5 text-blue-700 bg-white my-10 text-center font-bold leading-5">
            Discount on App
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <form
              onSubmit={handleSubmit}
              className="bg-gray-100 p-6 rounded-lg shadow-md grid grid-cols-2 gap-2"
            >
              <div className="mb-1">
                <label
                  htmlFor="B2CdiscountPercentage"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Discount Percentage for B2C %
                </label>
                <input
                  type="number"
                  id="B2CdiscountPercentage"
                  name="B2CdiscountPercentage"
                  value={discountData.B2CdiscountPercentage}
                  onChange={OnchangeHandler}
                  placeholder="%"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="discountPercentage"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Discount Percentage for B2B %
                </label>
                <input
                  type="number"
                  id="B2BdiscountPercentage"
                  name="B2BdiscountPercentage"
                  value={discountData.B2BdiscountPercentage}
                  onChange={OnchangeHandler}
                  placeholder="%"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              {/* <div className="mb-1">
                <label
                  htmlFor="eligibleUser"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Eligible User
                </label>
                <select
                  id="eligibleUser"
                  name="eligibleUser"
                  onChange={OnchangeHandler}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select State</option>
                  {eligibleUser.map((item) => {
                    return (
                      <>
                        <option
                          value={item.type}
                          selected={item.type == discountData.eligibleUser}
                        >
                          {item.type}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div> */}

              <button type="submit">
                <span className="bg-indigo-500  text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300">
                  Apply Discount
                </span>
              </button>
            </form>

            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">
                  Discount for B2B:
                </h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">
                  {getdiscount?.B2BdiscountPercentage} %
                </h2>
              </div>
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">
                  Discount for B2C:
                </h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">
                  {getdiscount?.B2CdiscountPercentage} %
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main modal */}
    </PageWrapper>
  );
};

export default DiscountAndCoupon;
