import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ContentBox from "../components/contents/ContentBox";
import PageWrapper from "../components/PageWrapper";
import OrderDetailsProduct from "../components/products/OrderDetailsProduct";
import { BaseURL, getData, putData2 } from "../FatchApi/FatchApi";
import { useParams } from "react-router-dom";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";

const OrderDetailsPage = () => {
  const { orderId } = useParams();

  const [orderStatus, setOrderStatus] = useState("");
  const [orderStatusValue, setOrderStatusValue] = useState([]);
  const [singleOrder, setSingleOrder] = useState("");

  const getOrderStatusValue = async () => {
    const res = await getData("api/order/get-order-status");
    setOrderStatusValue(res);
  };
  const updateView = () => {
    const res = putData2(`api/order/view-order/${orderId}`);
  };
  const getSingleOrder = async () => {
    const res = await getData(`api/order/get-single-order/${orderId}`);
    const [order] = res.Orders ? res.Orders : [];

    setSingleOrder(order ? order : "");
  };

  useEffect(() => {
    getSingleOrder();
    getOrderStatusValue();
    updateView();
  }, []);

  const updateStatus = async (status) => {
    const body = {
      orderstatus: status,
    };
    const data = JSON.stringify(body);
    const ContentType = "application/json";
    const res = await putData2(`api/order/update-order-status/${orderId}`, {
      data,
      ContentType,
    });
    if (res?.success) {
      getSingleOrder();
      toast.success("Order status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // getSingleOrder();
      getOrderStatusValue();
    } else {
      toast.error("Order status not updated");
    }
  };

  return (
    <PageWrapper>
      <ContentBox name={`Inquiry Id: ${orderId}`}>
        <Toastify close={"2000"} />
        <div className="border-b border-b-glitch-box"></div>
        <div className="px-5 py-5">
          {/* Ordered products */}
          <OrderDetailsProduct
            items={singleOrder?.items}
            price={111}
            units={2}
            quantity={1}
            image=""
          />
          <div className="mt-4">
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Item Total</span>
              <span>
                ₹
                {singleOrder?.orderSummary?.cartTotal +
                  singleOrder?.orderSummary?.disountValue}
              </span>
            </div>
            {singleOrder?.userId?.userType == "B2C" &&
            singleOrder?.orderSummary?.disountValue ? (
              <>
                <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
                  <span>
                    Discount on Purchase (only for{" "}
                    <span className="bg-[#d4ff32] px-2 text-black">B2C </span>)
                  </span>
                  <span>
                    -₹
                    {singleOrder?.orderSummary?.disountValue} (
                    {singleOrder?.orderSummary?.discountInPercentange} %)
                  </span>
                </div>

                <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
                  <span>Total after Discount</span>
                  <span>₹{singleOrder?.orderSummary?.cartTotal}</span>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Grand Total (Inc.of Taxes)</span>
              <span className="text-black px-2 bg-[#d4ff32]">
                ₹{singleOrder?.amount}
              </span>
            </div>
          </div>

          <hr className="w-full border-b border-b-glitch-box my-5" />

          {/* Customer details */}
          <div className="mt-4">
            <div className="text-white flex items-center justify-between">
              <div className="font-medium">CUSTOMER DETAILS</div>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Name</span>
              <span>
                {singleOrder.userId?.fullName} ({singleOrder.userId?.userType})
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Mobile</span>
              <div>
                <span>{singleOrder.userId?.number} </span>
                <button className="material-icons-outlined text-lg px-1 bg-blue-500 text-white rounded">
                  phone
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>State</span>
              <span>{singleOrder.userId?.state}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>City</span>
              <span>{singleOrder.userId?.city}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Pincode</span>
              <span>{singleOrder.userId?.pincode}</span>
            </div>
            {/* <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Payment</span>
              <Button>
                <span className="text-yellow-400 px-1">
                  {" "}
                  {singleOrder?.isPaid ? "Paid" : "Cash on Delivery"}
                </span>
              </Button>
            </div> */}
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span> Order Status</span>
              <Button>
                <span className="text-yellow-400 px-1">
                  {" "}
                  {singleOrder?.orderstatus}
                </span>
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 bg-white p-5 rounded-lg">
            {orderStatusValue?.map((status, i) => (
              <div>
                <button
                  key={i}
                  onClick={() => updateStatus(status)}
                  className={`
              flex-1 min-w-[120px] p-3 rounded-lg text-sm font-medium
              transition-all duration-200 ease-in-out
              ${
                singleOrder?.orderstatus === status
                  ? "bg-blue-600 text-white shadow-lg scale-105"
                  : "bg-white text-gray-600 border border-gray-200 hover:bg-gray-50 hover:border-gray-300"
              }
            `}
                >
                  {status}
                </button>
              </div>
            ))}
          </div>
        </div>
      </ContentBox>
    </PageWrapper>
  );
};

export default OrderDetailsPage;
