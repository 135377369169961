import React from "react";
import configs from "../../configs.json";
import { BaseURL } from "../../FatchApi/FatchApi";
import Offerbadge from "../Offerbadge/Offerbadge";

const CartDetails = ({ items, price, units, image, quantity }) => {
  console.log(items);
  return (
    <div className="container  bg-gradient-to-br from-purple-50 to-pink-50">
      <div className="space-y-2">
        {items?.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <div className="p-6">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-3">
                <div className="flex items-center mb-4 sm:mb-0">
                  <div className="relative w-16 h-16 mr-4 rounded-md overflow-hidden bg-gradient-to-br from-purple-100 to-pink-100 flex items-center justify-center">
                    {item.productId?.images[0] ? (
                      <img
                        src={`${BaseURL}/products/${item.productId.images[0]}`}
                        alt={item.productId?.productName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <svg
                        className="w-8 h-8 text-purple-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                        />
                      </svg>
                    )}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-purple-800">
                      {item.productId?.productName}
                    </h3>
                    <p className="text-sm">{item.variantData?.productInfo}</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 text-sm">
                <div className="bg-purple-50 p-3 rounded-lg ">
                  <p className="text-purple-600 mb-1 it">SKU</p>
                  <p className="font-medium text-purple-800">
                    {item.variantData.sku}
                  </p>
                </div>
                <div className="bg-green-50 p-3 rounded-lg">
                  <p className="text-green-600 mb-1">Size</p>
                  <p className="font-medium text-green-800">
                    {item.variantData.size}
                  </p>
                </div>
                <div className="bg-purple-50 p-3 rounded-lg">
                  <p className="text-purple-600 mb-1">Orderd Quantity</p>
                  <p className="font-medium text-purple-800">{item?.qty}</p>
                </div>

                <div className="bg-green-50 p-3 rounded-lg">
                  <p className="text-green-600 mb-1">Unit Selling Price</p>
                  <p className="font-medium text-green-800">
                    ₹{item.variantData.sellingPrice.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="mt-2 pt-2 border-t border-purple-100">
                <div className="flex justify-between items-center text-sm">
                  <span className="text-black  px-2 py-1 font-semibold">
                    Total (Selling Price × Quantity){" "}
                  </span>
                  <span className="font-medium  px-2 py-1 font-semibold">
                    ₹{(item.variantData?.sellingPrice * item?.qty).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartDetails;
