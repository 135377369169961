import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ContentBox from "../components/contents/ContentBox";
import PageWrapper from "../components/PageWrapper";
import OrderDetailsProduct from "../components/products/OrderDetailsProduct";
import { BaseURL, getData, postData, putData2 } from "../FatchApi/FatchApi";
import { useParams } from "react-router-dom";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";
import CartDetails from "../components/Cart/CartDetails";
import Spinner from "../components/Loader/Spinner";

const CartDetailsPage = () => {
  const { userId } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [userCart, setuserCart] = useState("");
  const [user, setUser] = useState("");

  const getuserCart = async () => {
    const res = await getData(`/api/cart/user-get-cart/${userId}`);
    const res2 = await getData(`/api/user/get-single-user/${userId}`);
    setuserCart(res.cart);
    setUser(res2.user);
  };
  useEffect(() => {
    getuserCart();
  }, []);
  const remind = async () => {
    setSpinner(true);
    const formData = {
      contanttype: "application/json",
      data: JSON.stringify({ userId: userId }),
    };
    const res = await postData("api/email/send-cart-mail", formData);
    if (res.success) {
      setSpinner(false);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setSpinner(false);
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <PageWrapper>
      {spinner ? <Spinner /> : ""}
      <Toastify close={"2000"} />
      <div className="border-b border-b-glitch-box bg-[#3B2939]">
        <div className="px-5 py-5">
          <CartDetails
            items={userCart?.items}
            price={111}
            units={2}
            quantity={1}
            image=""
          />

          <hr className="w-full border-b border-b-glitch-box my-5" />

          <div className="mt-4">
            <div className="text-white flex items-center justify-between">
              <div className="font-medium">CUSTOMER DETAILS</div>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Name</span>
              <span>
                {user?.fullName} ({user?.userType})
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Mobile</span>
              <div>
                <span>{user?.number} </span>
                <button className="material-icons-outlined text-lg px-1 bg-blue-500 text-white rounded">
                  phone
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>State</span>
              <span>{user?.state}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>City</span>
              <span>{user?.city}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Pincode</span>
              <span>{user?.pincode}</span>
            </div>

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span> Email</span>
              <Button>
                <span className="text-yellow-400 px-1"> {user?.email}</span>
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 bg-white p-5 rounded-lg">
            <button
              onClick={remind}
              class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Remind Customer
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default CartDetailsPage;
