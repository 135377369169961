import React, { useState } from "react";
import { BaseURL, postData, putData } from "../FatchApi/FatchApi";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Spinner from "./Loader/Spinner";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import Toastify from "./Toast/Toastify";

const BlogAddForm = ({ id }) => {
  const [spinner, setSpinner] = useState(false);
  const [content, setContent] = useState("");

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      fetch(`${BaseURL}/api/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({ data: { link: `${BaseURL}${data.url}` } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   const { blocks } = convertToRaw(editorState.getCurrentContent());
  // };

  // const convertContentToHTML = () => {
  //   const contentState = editorState.getCurrentContent();
  //   const rawContentState = convertToRaw(contentState);
  //   const html = draftToHtml(rawContentState);
  //   setPoint(html);
  // };
  const initialFormData = {
    title: id.title ? id.title : "",
    publishDate: id.publishDate ? id.publishDate : "",
    images: "",
    content: id.content ? id.content : "",
  };

  const [productData, setProductData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProductData((prevData) => ({
      ...prevData,
      images: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const formData = new FormData();

    let contanttype = "";
    formData.append("title", productData.title);
    formData.append("publishDate", productData.publishDate);
    formData.append("content", productData.content);
    formData.append("images", productData.images);
    // let formObj = Object.fromEntries(formData.entries());
    // console.log(formObj);

    if (id) {
      const Id = id._id;

      const res = await putData(`api/blog/update-blog/${Id}`, {
        data: formData,
        contanttype,
      });

      if (res.success) {
        window.location.reload();
      } else {
        setSpinner(false);
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await postData("api/blog/add-blog", {
        data: formData,
        contanttype,
      });

      if (res.success) {
        setSpinner(false);
        window.location.reload();
      } else {
        setSpinner(false);
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <>
      {spinner ? <Spinner /> : ""}
      <Toastify close={2000} />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 mx-auto">
          <div className="mb-4">
            <label htmlFor="title" className="block mb-1 font-medium">
              Title
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="title"
              name="title"
              placeholder="Title"
              value={productData.title}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Blog Publish Date
            </label>
            <input
              type="Date"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Blog Publish Date"
              id="publishDate"
              name="publishDate"
              value={productData.publishDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="block mb-1 font-medium">
              Image
            </label>

            <input
              type="file"
              id="images"
              name="images"
              onChange={handleFileChange}
              multiple
            />
          </div>
        </div>
        <div className="custom-editor">
          <ReactQuill
            value={productData.content}
            onChange={(value) =>
              setProductData((prev) => ({ ...prev, content: value }))
            }
            modules={BlogAddForm.modules}
            formats={BlogAddForm.formats}
            placeholder="Start typing..."
          />
          <button
            className="px-4 py-2 mt-5  text-white bg-blue-500 rounded hover:bg-blue-600 float-right text-center"
            type="submit"
          >
            Upload Blog
          </button>
        </div>
      </form>
    </>
  );
};
BlogAddForm.modules = {
  toolbar: [
    [
      { header: "1" },
      { header: "2" },
      { header: "3" },
      { header: "4" },
      { header: "5" },
      { header: "6" },
      { font: [] },
    ],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link"],
    ["clean"], // Remove formatting
  ],
};

// Define formats to support
BlogAddForm.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "color",
  "background",
  "align",
  "link",
  "image",
];
export default BlogAddForm;
