import React from "react";
import { DeleteData } from "../../FatchApi/FatchApi";
import { Link } from "react-router-dom";

const Cart = ({ data, onchange, setshow, setEditID }) => {
  return (
    <Link
      to={`/manage-cart/${data.user?._id}`}
      className={"inline-block w-full py-1"}
    >
      <div className="w-full py-1 flex flex-wrap items-center justify-between">
        {/* Product preview thumbnail */}
        <div className="flex relative justify-center items-center w-28 h-28 bg-orange-400 rounded  ">
          <p className="font-bold  text-white text-xl">
            {data?.itemLength} Items
          </p>
        </div>

        <div className="w-full md:flex-1 p-4">
          <div className="text-glitch-orange text-xl">
            {data.user?.fullName}
          </div>

          {/* Product status */}
          <div className="text-green-500 text-sm md:text-xs flex items-center">
            {/* {data.inStock ? "In stock" : "Out of stock"} */}
            {/* Toggle product available/unavailable checkbox */}
          </div>
        </div>

        {/* More options button */}
        {/* <div className="w-full md:w-auto flex flex-col items-center md:items-start justify-around mt-4 md:mt-0">
        <button
          className="text-white inline-block mb-2 md:mb-0"
          onClick={() => deleteproduct()}
        >
          <img
            src="../../../../delete.png"
            style={{ width: "80%" }}
            alt="Delete"
          />
        </button>
        <button
          className="text-white inline-block"
          onClick={() => editproduct()}
        >
          <img src="../../../../edit.png" style={{ width: "80%" }} alt="Edit" />
        </button>
      </div> */}
      </div>
    </Link>
  );
};

export default Cart;
