import React, { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import SortByTag from "../components/SortByTag";
import ContentBox from "../components/contents/ContentBox";
import ProductAddForm from "../components/addProduct";
import PageWrapper from "../components/PageWrapper";
import { getData } from "../FatchApi/FatchApi";
import QueryCard from "../components/CustomerQueries/QueryCard";

const CustomerQuery = () => {
  const [Queries, setQueries] = useState([]);

  const getQueries = async () => {
    const res = await getData(`api/customer-form/get-all-queries`);
    setQueries(res.queries);
  };
  useEffect(() => {
    getQueries();
  }, []);
  return (
    <PageWrapper>
      <div className="w-full">
        <ContentBox name="Customer Queries">
          <>
            <div className="border-b border-b-glitch-box">
              <SortByTag tags={[]} />
            </div>

            {/* Products list */}

            <Suspense fallback={<LoadingIndicator />}>
              <ul className="sm:px-5 px-1 bg-transparent">
                {Queries.map((res, i) => (
                  <li
                    className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                    key={i}
                  >
                    <QueryCard
                      data={res}
                      // onchange={getProduct}
                      //setshow={setshow}
                      //setEditID={setEditID}
                    />
                  </li>
                ))}
              </ul>
            </Suspense>
          </>
        </ContentBox>
      </div>
    </PageWrapper>
  );
};

export default CustomerQuery;
