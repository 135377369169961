import React, { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import ContentBox from "../components/contents/ContentBox";
import PageWrapper from "../components/PageWrapper";
//import SortByTag from "../components/SortByTag";
import configs from "../configs.json";
import { getData } from "../FatchApi/FatchApi";
import { useParams, useSearchParams } from "react-router-dom";

const OrderedProductPreview = React.lazy(() =>
  import("../components/products/OrderedProductPreview")
);

const ManageUserOrder = () => {
  const { userId } = useParams();

  const [orderedProductList, setOrderedProductList] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  const getAllOrders = async () => {
    const res = await getData(`api/orders/get-order/${userId}`);

    const sortedProducts = res.Orders?.sort((a, b) => {
      return new Date(b.orderDate) - new Date(a.orderDate);
    });

    setAllOrders(sortedProducts);
  };
  useEffect(() => {
    getAllOrders();
  }, []);

  // Fetch ordered products
  useEffect(() => {
    fetch(`${configs.proxy}/orders.json`)
      .then((res) => res.json())
      .then(({ data }) => {
        setOrderedProductList(data);
      });
  }, []);

  return (
    <PageWrapper>
      <ContentBox name="Manage Orders">
        <>
          <div className="border-b border-b-glitch-box">
            {/* <SortByTag
              tags={["Pending (129)", "Accepted (13)", "Shipped (6)"]}
            /> */}
          </div>

          {/* Products list */}
          <div className="mt-5"></div>
          <Suspense fallback={<LoadingIndicator />}>
            <ul className="px-5 bg-transparent">
              {allOrders?.map((Order, i) => (
                <li
                  className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                  key={i}
                >
                  <OrderedProductPreview orderId={Order?._id} />
                </li>
              ))}
            </ul>
          </Suspense>
        </>
      </ContentBox>
    </PageWrapper>
  );
};

export default ManageUserOrder;
