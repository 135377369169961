import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { postData } from "../FatchApi/FatchApi";

const Verifyotp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [code, setCode] = useState("");

  const verifyOtp = async () => {
    const data = JSON.stringify({ otp: code, email: state?.email });
    const contanttype = "application/json";
    const res = await postData("api/auth/verifyotp", { data, contanttype });
    if (res.success) {
      const Token = res.data.accessToken;
      localStorage.setItem("token", Token);
      if (res?.userVerification?.data?.isAdmin) {
        navigate("/Dashboard");
      }
    }
    alert(res?.message);
  };

  const submithandler = () => {
    verifyOtp();
  };

  return (
    <section>
      <div class=" py-20 my-10 px-3">
        <div class="container mx-auto">
          <div class="">
            <div class="w-full">
              <div class="bg-[#977f9c] h-50 py-3 rounded text-center">
                <h1 class="text-2xl text-white font-bold">OTP Verification</h1>
                <div class="flex flex-col my-4 text-white ">
                  <span>Enter the OTP you received at</span>
                  {/* <span class="font-bold p-1">{profile.email}</span> */}
                </div>
                <div className="flex justify-center align-center my-2">
                  <OtpInput
                    value={code}
                    onChange={setCode}
                    numInputs={6}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: "1px solid black",
                      borderRadius: "8px",
                      width: "50px",
                      height: "50px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                      margin: "2px",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <div class="flex justify-center text-center  ">
                  <button class="flex items-center text-blue cursor-pointer text-sm">
                    Resend OTP
                  </button>
                </div>

                <div class="flex justify-center text-center mt-5 ">
                  <button
                    onClick={() => submithandler()}
                    class="flex items-center text-white cursor-pointer rounded px-5 py-1 bg-red"
                  >
                    Submit OTP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Verifyotp;
