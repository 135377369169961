import React, { useEffect, useState } from "react";
import { getData, postData, putData } from "../FatchApi/FatchApi";

import Spinner from "./Loader/Spinner";

import VariantModal from "./VariantModal";

const ProductAddForm = ({ id }) => {
  const initialFormData = {
    productName: id.productName ? id.productName : "",
    images: id.images ? id.images : "",
    category: id.category?.length !== 0 ? id.category?.map((id) => id._id) : [],

    productManual: id.productManual ? id.productManual : "",
    promoted: id.isPromoted ? id.isPromoted : "",
  };
  const [category, setCategory] = useState([]);

  const [variant, setvariant] = useState({
    sku: "",
    productInfo: "",
    size: "",
    quantity: "",
    inStock: "",
    mrp: "",
    tax: "",
    discount: 0,
    sellingPrice: "",
  });
  const [variants, setVariants] = useState(id.productVariant || []);
  const [showSpinner, setShowSpinner] = useState(false);
  const [productData, setProductData] = useState(initialFormData);
  const [variantEdit, setvariantEdit] = useState({
    edit: "",
    index: "",
  });
  const handleChange = (e) => {
    const { name, options } = e.target;

    if (name === "category") {
      const selectedValues = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setProductData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      const { value } = e.target;
      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      images: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      productData.productName !== "" &&
      productData.images.length !== 0 &&
      productData.category.length !== 0 &&
      variants.length !== 0
    ) {
      setShowSpinner(true);
      const contanttype = "";
      const formData = new FormData();

      formData.append("productName", productData.productName);

      formData.append("isPromoted", productData.promoted);
      formData.append("productManual", productData.productManual);

      if (productData.images !== 0 || productData.images !== " ") {
        productData.images?.forEach((image, index) => {
          formData.append(`images`, image);
        });
      }
      productData.category?.forEach((category, index) => {
        formData.append("category", category);
      });
      variants.forEach((variant, index) => {
        for (const [key, value] of Object.entries(variant)) {
          formData.append(`productVariant[${index}][${key}]`, value);
        }
      });
      if (id) {
        const Id = id._id;
        const res = await putData(`api/product/update-products/${Id}`, {
          data: formData,
          contanttype,
        });

        if (res && res.success) {
          // setProductData(initialFormData1);
          // alert(res?.message);
          window.location.reload();
        } else {
          setShowSpinner(false);
          alert(res?.msg);
        }
      } else {
        const res = await postData("api/product/add-products", {
          data: formData,
          contanttype,
        });
        if (res && res.success) {
          // setProductData(initialFormData1);
          // alert(res.message);
          window.location.reload();
        } else {
          setShowSpinner(false);
          alert(res?.msg);
        }
      }
    } else {
      alert("Please Enter all details of product along with Variant");
    }
  };
  const getDynamicData = async () => {
    const cat = await getData("api/category/get-category");

    setCategory(cat.categories);
  };
  useEffect(() => {
    getDynamicData();
  }, []);

  // variant functionality
  const toggleModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
  };
  const closeModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
    setvariant({
      sku: "",
      productInfo: "",
      size: "",
      quantity: "",
      inStock: "",
      mrp: "",
      discount: 0,
      sellingPrice: "",
    });
    setvariantEdit({
      edit: "",
      index: "",
    });
  };

  const variantOnchangeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name == "inStock") {
      setvariant((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setvariant((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (variant.mrp && variant.discount >= 0) {
      const calculatedSellingPrice =
        variant.mrp - (variant.mrp * variant.discount) / 100;
      setvariant((prevState) => ({
        ...prevState,
        sellingPrice: calculatedSellingPrice, // Rounded to 2 decimal places
      }));
    }
  }, [variant.mrp, variant.discount]);
  const editVariant = (vari, index) => {
    setvariantEdit({
      edit: true,
      index: index,
    });
    setvariant(vari);
    toggleModal();
  };
  const AddingVariant = (variantEdit) => {
    if (
      variant.discount !== "" &&
      variant.mrp !== "" &&
      variant.productInfo !== "" &&
      variant.quantity !== "" &&
      variant.sellingPrice !== "" &&
      variant.size !== "" &&
      variant.sku !== ""
    ) {
      if (variantEdit.edit) {
        const updatedVariant = [...variants];
        updatedVariant[variantEdit.index] = variant;
        setVariants(updatedVariant);
        closeModal();
      } else {
        setVariants((prev) => [...prev, variant]);
        closeModal();
        setvariant({
          sku: "",
          productInfo: "",
          size: "",
          quantity: "",
          inStock: "",
          mrp: "",
          discount: 0,
          sellingPrice: "",
          tax: "",
        });
      }
    } else {
      alert("varint could not be add with empty field");
    }
  };
  const removeVariant = (index) => {
    const updateVariant = [...variants];
    updateVariant.splice(index, 1);
    setVariants(updateVariant);
  };

  return (
    <>
      <div className="relative min-h-screen">
        {/* <Loader /> */}
        {showSpinner ? <Spinner /> : " "}
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit}
          className="grid grid-cols-2 gap-4 mx-auto"
        >
          <div className="mb-4">
            <label htmlFor="category" className="block mb-1 font-medium">
              Category
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="category"
              name="category"
              value={productData.category}
              onChange={handleChange}
              required
              multiple
            >
              <option value="">Select Product Category</option>
              {category?.map((item) => (
                <option
                  value={item._id}
                  selected={initialFormData?.category == item._id}
                >
                  {item?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="name" className="block mb-1 font-medium">
              Product Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="productName"
              name="productName"
              placeholder="Enter product name"
              value={productData.productName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="image" className="block mb-1 font-medium">
              Image Upload
            </label>
            <input
              type="file"
              id="images"
              name="images"
              onChange={handleFileChange}
              multiple
            />
          </div>
          <div className="mb-4">
            <label htmlFor="productManual" className="block mb-1 font-medium">
              Product Manual PDF link or drive link
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="productManual"
              name="productManual"
              placeholder="https://drive.google.com/drive/folders/16ockExxxxxdiFYmgm7gANvQMRaK8VQhjNR?usp=sharing"
              value={productData.productManual}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="promoted" className="block mb-1 font-medium">
              Promoted
            </label>
            <select
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="tax"
              name="promoted"
              value={productData.promoted}
              onChange={handleChange}
              required
            >
              <option selected> Select One</option>
              <option value={true} selected={initialFormData?.promoted}>
                Yes
              </option>
              <option value={false} selected={!initialFormData?.promoted}>
                No
              </option>
            </select>
          </div>
        </form>
        <div className="flex justify-end">
          <button
            className=" px-2 py-2 text-white bg-[#28282B] rounded hover:bg-black"
            onClick={toggleModal}
          >
            + Add Product Varinat
          </button>
        </div>
        <VariantModal
          toggleModal
          closeModal={closeModal}
          variantOnchange={variantOnchangeHandler}
          variant={variant}
          AddVariant={AddingVariant}
          variantEdit={variantEdit}
        />
        <div className="grid mb-3">
          {variants.map((vari, index) => (
            <div
              key={index}
              className="w-full overflow-x-auto bg-white shadow-md rounded-lg mt-4"
            >
              <div className="min-w-max">
                <div className="flex items-center bg-gray-100 px-4 py-3 text-sm font-semibold text-gray-700">
                  <div className="w-32 flex-shrink-0">SKU</div>
                  <div className="w-48 flex-shrink-0">Product Info</div>
                  <div className="w-24 flex-shrink-0">Size</div>
                  <div className="w-24 flex-shrink-0">Quantity</div>
                  <div className="w-24 flex-shrink-0">Tax</div>
                  <div className="w-24 flex-shrink-0">In Stock</div>
                  <div className="w-24 flex-shrink-0">MRP</div>
                  <div className="w-24 flex-shrink-0">Discount</div>
                  <div className="w-32 flex-shrink-0">Selling Price</div>
                  <div className="w-32 flex-shrink-0">Actions</div>
                </div>
                <div className="flex items-center px-4 py-3 text-sm text-gray-600 border-t border-gray-200">
                  <div className="w-32 flex-shrink-0">{vari.sku}</div>
                  <div className="w-48 flex-shrink-0">{vari.productInfo}</div>
                  <div className="w-24 flex-shrink-0">{vari.size}</div>
                  <div className="w-24 flex-shrink-0">{vari.quantity}</div>
                  <div className="w-24 flex-shrink-0">{vari?.tax}</div>
                  <div className="w-24 flex-shrink-0">
                    {vari.inStock ? "YES" : "NO"}
                  </div>
                  <div className="w-24 flex-shrink-0">{vari.mrp}</div>
                  <div className="w-24 flex-shrink-0">{vari.discount}%</div>
                  <div className="w-32 flex-shrink-0">{vari.sellingPrice}</div>
                  <div className="w-32 flex-shrink-0 flex space-x-2">
                    <button
                      className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                      onClick={() => editVariant(vari, index)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => removeVariant(index)}
                      className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center my-10">
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Save Product
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductAddForm;
