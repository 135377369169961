import React, { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import ContentBox from "../components/contents/ContentBox";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PageWrapper from "../components/PageWrapper";
import SortByTag from "../components/SortByTag";
import Search from "../components/Search";

import configs from "../configs.json";
import { BaseURL, getData, postData } from "../FatchApi/FatchApi";
import { useNavigate } from "react-router-dom";

const OrderedProductPreview = React.lazy(() =>
  import("../components/products/OrderedProductPreview")
);

const DashboardPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [saleData, setSaleData] = useState([]);

  const getAllOrders = async () => {
    const res = await getData("api/chart/order-data");
    const res2 = await getData("api/chart/order-sales");
    if (res == "Token is not valid!") {
      localStorage.clear();
      navigate("/");
    }
    setData(res.formattedOrders);
    setSaleData(res2.formattedData);
  };

  useEffect(() => {
    getAllOrders();
  }, []);
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Monthly Orders",
    },
    xAxis: {
      categories: data?.map((order) => order.month),
    },
    yAxis: {
      title: {
        text: "Number of Orders",
      },
    },
    series: [
      {
        name: "Orders",
        data: data?.map((order) => order.count),
      },
    ],
  };
  const options2 = {
    chart: {
      type: "line",
    },
    title: {
      text: "Monthly Sales Report",
    },
    xAxis: {
      categories: saleData?.map((order) => order.month),
    },
    yAxis: {
      title: {
        text: "Amount",
      },
    },
    series: [
      {
        name: "Sales",
        data: saleData?.map((order) => order.totalAmount),
      },
    ],
  };

  return (
    <PageWrapper>
      <div className="w-full">
        <div className="flex justify-between relative">
          <div>
            <h1 className="text-lg text-gray-100 font-bold leading-5">
              Hello {configs.userName}!
            </h1>
            <p className="text-gray-300 leading-5">
              Welcome back {configs.userName.split(" ")[0]}, and great to meet
              you
            </p>
          </div>
          {/* <div className="ml-1">
            <Search />
          </div> */}
        </div>

        {/* Store status */}
        {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 overflow-x-auto mt-10">
          <DisplaySection
            displayText="$5333"
            subText="Total Sales"
            icon={TotalSalesIcon}
          />

          <DisplaySection
            icon={ShoppingCartIcon}
            displayText={"133"}
            subText={"Product Orders"}
          />

          <DisplaySection
            icon={StoreIcon}
            displayText={"3344"}
            subText={"Store Views"}
          />

          <DisplaySection
            icon={LeadershipIcon}
            displayText={"234"}
            subText={"Product Views"}
          />
        </div> */}

        {/* Active orders */}
        <ContentBox name="Charts" className="bg-white">
          <>
            <div className="border-b border-b-glitch-box">
              <SortByTag tags={[]} />
            </div>

            <div className="mt-5"></div>
            <Suspense fallback={<LoadingIndicator />}>
              <div className="bg-white grid grid-cols-2">
                <div className=" my-10">
                  <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
                <div className=" my-10">
                  <HighchartsReact highcharts={Highcharts} options={options2} />
                </div>
              </div>
            </Suspense>
          </>
        </ContentBox>
      </div>
    </PageWrapper>
  );
};

export default DashboardPage;
