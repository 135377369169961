import React from "react";

const VariantModal = ({
  closeModal,
  variantOnchange,
  variant,
  AddVariant,
  variantEdit,
}) => {
  return (
    <>
      <div
        id="crud-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="hidden overflow-y-auto overflow-x-hidden fixed t z-50 justify-center items-center w-full md:inset-0  max-h-full"
      >
        <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Add Varinat
              </h3>
              <button
                type="button"
                onClick={closeModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <form className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className=" col-span-2 sm:col-span-1">
                  <label
                    htmlFor="sku"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    SKU Code
                  </label>
                  <input
                    type="text"
                    name="sku"
                    id="sku"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="ITEM CODE"
                    value={variant.sku}
                    required
                    onChange={variantOnchange}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="size"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Product Size
                  </label>
                  <input
                    type="text"
                    name="size"
                    id="size"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="10 X 10"
                    value={variant.size}
                    onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="productInfo"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Product Info
                  </label>
                  <input
                    type="text"
                    name="productInfo"
                    id="productInfo"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Info"
                    value={variant.productInfo}
                    onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="quantity"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Quantity
                  </label>
                  <input
                    type="number"
                    name="quantity"
                    id="quantity"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="50"
                    value={variant?.quantity}
                    onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="tax"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tax
                  </label>
                  <select
                    id="countries"
                    name="tax"
                    onChange={variantOnchange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected> Select One</option>
                    <option value={0} selected={variant?.tax == 0}>
                      GST_0
                    </option>
                    <option value={12} selected={variant?.tax == 12}>
                      GST_12
                    </option>
                    <option value={18} selected={variant?.tax == 18}>
                      GST_18
                    </option>
                    <option value={28} selected={variant?.tax == 28}>
                      GST_28
                    </option>
                    <option value={3} selected={variant?.tax == 3}>
                      GST_3
                    </option>
                    <option value={5} selected={variant?.tax == 5}>
                      GST_5
                    </option>
                    <option
                      value={"GST_APPAREL"}
                      selected={variant?.tax == "GST_APPAREL"}
                    >
                      GST_APPAREL
                    </option>
                  </select>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="mrp"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    MRP
                  </label>
                  <input
                    type="number"
                    name="mrp"
                    id="mrp"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="1999"
                    value={variant?.mrp}
                    onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="discount"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Discount(%)
                  </label>
                  <input
                    type="number"
                    name="discount"
                    id="discount"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="10%"
                    value={variant?.discount}
                    onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="sellingPrice"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Selling Price
                  </label>
                  <input
                    type="number"
                    name="sellingPrice"
                    id="sellingPrice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="1900"
                    value={variant?.sellingPrice}
                    disabled
                    // onChange={variantOnchange}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="inStock"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    In Stock
                  </label>
                  <input
                    type="checkbox"
                    name="inStock"
                    checked={variant.inStock}
                    onChange={variantOnchange}
                    id="inStock"
                    className="w-5 h-5 mx-auto border border-gray-300 rounded-md bg-white checked:bg-blue-500 checked:border-blue-500 transition-all duration-200 ease-in-out cursor-pointer"
                  />
                </div>
              </div>
            </form>
            <div className="flex justify-end mx-3 pb-3">
              <button
                onClick={() => AddVariant(variantEdit)}
                className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {variantEdit.edit ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VariantModal;
